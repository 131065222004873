const palette = {
  primary: "#151515",
  secondary: "#FFF",
  black: "#000",
  amethystSmoke: "#9A9AAF",
  porcelain: "#EBF2F7",
  carbonGrey: "#5B5B5B",
  mediumGreen: "#1FAD3F",
  darkMintGreen: "#24CA49",
  carminePink: "#EF4335",
  purpleBlue: "#5A32FB",
  purpleDaffodil: "#9948FF",
  blueChalk: "#EDEDF8",
  lightBlack: "rgba(0, 0, 0, 0.1)",
  mediumBlack: "rgba(21, 21, 21, 0.8)",
  lightGray: "rgba(0, 0, 0, 0.5)",
  overlay: "rgba(0, 0, 0, 0.8)",
  mediumGrey: "rgba(109, 110, 118, 1)",
  backgroundTitle: "rgba(243, 243, 243, 1)",
};

export default palette;
