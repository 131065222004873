import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@emotion/react";
import theme from "../../src/theme";
import { StylesProvider } from "@mui/styles";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
}
