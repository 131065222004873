import { createTheme } from "@mui/material/styles";
import palette from "./palette";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 481,
      md: 641,
      lg: 901,
      xl: 1200,
    },
  },

  typography: {
    color: palette.primary,
    fontFamily: ["Poppins, sans-serif"].join(","),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',

    p: {
      margin: 0,
      padding: 0,
    },

    h1: {
      fontSize: "82px",
      fontWeight: 500,
      lineHeight: "90px",
      letterSpacing: "-0.02em",

      "& strong": {
        color: palette.purpleBlue,
        fontSize: "82px",
        fontWeight: 600,
        lineHeight: "90px",
      },

      "@media (max-width: 900px)": {
        fontSize: "34px",
        lineHeight: "42px",

        "& strong": {
          fontSize: "34px",
          lineHeight: "42px",
        },
      },
    },

    h2: {
      margin: 0,
      padding: 0,
      fontSize: "48px",
      fontWeight: 600,
      lineHeight: "72px",
      letterSpacing: "-0.02em",

      "& strong": {
        fontSize: "48px",
        fontWeight: 600,
        lineHeight: "72px",
      },

      "@media (max-width: 900px)": {
        fontSize: "34px",
        lineHeight: "40px",

        "& strong": {
          fontSize: "34px",
          lineHeight: "40px",
        },
      },
    },

    h3: {
      margin: 0,
      padding: 0,
      fontSize: "34px",
      fontWeight: 600,
      lineHeight: "51px",
    },

    h4: {
      margin: 0,
      padding: 0,
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",

      "& strong": {
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "32px",
      },
    },

    body1: {
      margin: 0,
      padding: 0,
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "30px",
      letterSpacing: "0.15px",

      "& strong": {
        color: palette.purpleBlue,
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "30px",
      },

      "@media (max-width: 640px)": {
        fontSize: "16px",
        lineHeight: "26px",

        "& strong": {
          fontSize: "16px",
          lineHeight: "26px",
        },
      },
    },

    body2: {
      margin: 0,
      padding: 0,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "26px",

      "& strong": {
        fontSize: "16px",
        lineHeight: "26px",
      },
    },

    subtitle1: {
      margin: 0,
      padding: 0,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
    },

    subtitle2: {
      margin: 0,
      padding: 0,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },

    caption: {
      margin: 0,
      padding: 0,
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
    },

    overline: {
      margin: 0,
      padding: 0,
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "16px",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '& *': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            outline: 'none',
            boxSizing: 'border-box',
          },

          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "21px",
      },
    },

    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 64,
        },
      },
    },
  },
});

export default theme;
